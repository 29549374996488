@import "assets/scss/vars";
@import "assets/scss/home";
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: "Inter", sans-serif;
}
.kanit-font {
  font-family: "Kanit", sans-serif !important;
}
:root {
  --primary-color-new: #085133;
  --secondary-color: #ffcd05;
  --tertiary-color: #231f20;
  --gray-text-color: #333333;
  --light-gray-color: #555555;
  --green-color: #66bc48;
  --light-color: #fbfcf4;
  --white-color: #ffffff;
  --btn-danger-color: #d86737;
  --blue-color: #085133;
  --light-arrow-color: #f1f1f1;
  --dark-gray-color: #707070;
}
.primary-color-new {
  color: var(--primary-color-new) !important;
}
.secondary-color {
  color: var(--secondary-color);
}
.tertiary-color {
  color: var(--tertiary-color);
}
.green-color {
  color: var(--green-color);
}
.light-color {
  color: var(--light-color);
}
.btn-danger-color {
  color: var(--btn-danger-color);
}
.gray-text-color {
  color: var(--gray-text-color);
}
.light-gray-color {
  color: var(--light-gray-color);
}
.blue-color {
  color: var(--blue-color);
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Kanit", sans-serif !important;
}
.font-weight-500 {
  font-weight: 500;
}
button {
  &.btnDefault {
    font-size: 16px;
    font-weight: 500;
    padding: 8px 24px;
    border-radius: 10px;
    outline: 0;
    text-transform: capitalize;
    box-shadow: none;
    &.btnSmalls {
      padding: 5px 24px;
    }
  }
  &.btnPrimary {
    background: var(--primary-color-new);
    border: solid 1px var(--primary-color-new);
    color: var(--white-color);
  }
   &.btnLemon {
    background: var(--green-color);
    border: solid 1px var(--green-color);
    color: var(--white-color);
  }
  &.btnOutline {
    background: var(--white-color);
    border: solid 1px var(--primary-color-new);
    color: var(--primary-color-new);
  }
  &.btnDanger {
    background: var(--btn-danger-color);
    border: solid 1px var(--btn-danger-color);
    color: var(--white-color);
  }
  &:disabled {
    opacity: 0.5;
  }
}
// common class end
$blue: #085133;
* {
  word-break: break-word;
}
body p {
  word-wrap: break-word;
  font-weight: normal;
}
.pointer {
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}
h4 {
  &.color-grey {
    font-family: "Inter", sans-serif !important;
  }
}
// topbar css
.fs30{
  font-size: 30px;
}
.fs34{
  font-size: 34px;
}
.fs28 {
  font-size: 28px;
}
.fs20 {
  font-size: 20px;
}
.fs22 {
  font-size: 22px;
}
.fs18 {
  font-size: 18px;
}
.fs15 {
  font-size: 15px !important;
}
.fs16 {
  font-size: 16px !important;
}
.text-blue {
  color: var(--blue-color) !important;
}
.fw500 {
  font-weight: 500;
}
.min-h280{
  min-height: 280px;
}
.topbarWrapper {
  & .logo {
    padding: 1rem;
    box-shadow: 1px -7px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    z-index: 9;

    & img {
      margin: 0;
      padding-left: 15px;
      width: 120px;
    }
  }
}
.topbar {
  padding: 1rem 3.4rem;
  position: relative;
  background-color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;

  & .icon-notification,
  .icon-settings {
    margin-top: 0.4rem;
    display: inline-block;
    color: #333333;
    margin-right: 16px;
    position: relative;
  }
  .dropdown {
    display: inline-block;
  }
  .dropdown button#notification-dropdown {
    background: none;
    border: none;
    padding: 0;
    .icon-notification {
      margin-right: 0;
      margin-top: 0;
    }
  }
  .dropdown-menu:before {
    content: "";
    width: 15px;
    height: 15px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid var(--primary-color-new);
    position: absolute;
    right: 113px;
    top: -13px;
  }
  .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }
  .dropdown-menu {
    right: -110px !important;
    left: auto !important;
    top: 80px !important;
    background: #ffffff;
    box-shadow: 8px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    width: 512px;
    padding: 0;
    transform: none !important;
    border: none;
  }
  .dropdown-head {
    background: #fff7e3;
    border-radius: 15px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .dropdown-footer {
    text-align: center;
    color:#085133;
    text-transform: capitalize;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background: none;
    color: #333333;
  }
  .dropdown-item:focus {
    outline: none;
  }
  ul.notification-list {
    padding: 0 25px 0 35px;
    list-style-type: none;
    margin-bottom: 0;
  }
  ul.notification-list li {
    position: relative;
    border-bottom: 0.5px solid #d1d1d1;
    padding: 20px 0 8px;
  }

  ul.notification-list li::before {
    content: "\2022";
    color: #d1d1d1;
    font-weight: bold;
    width: 1em;
    margin-left: -0.8em;
    margin-top: -8px;
    float: left;
    font-size: 25px;
  }
  ul.notification-list li.active::before {
    color: #fec32d;
  }
}
// .icon-notification:after {
//   content: "";
//   position: absolute;
//   width: 11px;
//   height: 11px;
//   background: #ff0000;
//   border-radius: 50%;
//   top: 1px;
//   right: 1px;
// }
.notification-sections.dropdown .dropdown-toggle:after {
    display: none;
}

.topbar,
.searchParent {
  & .searchTxt {
    background-color: #fafafa;
    border-radius: 40px;
    font-size: 15px;
    color: var(--gray-text-color);
    padding-right: 2.8rem;
    padding-left: 1.5rem;
    border: 0.5px solid #e2e2e2;
    height: 45px;
    &::placeholder {
      color: #c2c2c2;
    }
  }

  & .searchTxt:focus {
    box-shadow: 0 0 0 0.2rem rgba(161, 161, 161, 0.25);
  }

  & .icon-search {
    position: absolute;
    right: 1rem;
    top: 0.7rem;
    color: var(--light-gray-color);
    font-size: 20px;
  }
}
.line-ht {
  line-height: 28px;
}
.box-shadow {
  box-shadow: 5px 4px 10px rgba(0, 0, 0, 0.05);
}

// sidebar css
.sidebar {
  background-color: #fff;
  box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.1);

  & .sidebarNav {
    padding: 1.5rem 0rem 0.5rem 0rem;

    .dropdown-toggle::after {
      display: none;
    }

    & a {
      display: flex;
      align-items: center;
      padding: 1rem 0.8rem 1rem 1.2rem;
      color: #555555;
      position: relative;
      font-size: 14px;
      &.noActives.active {
        color: #555555;
        background: #fff;

        & > span[class^="icon-"] {
          fill: #555555;
          color: #555555;
          &:before {
            color: #555555;
          }
        }
        &:after {
          background: #fff;
        }
        &:hover {
          color: #fec32d;
          background: #f4f4f4;

          & > span[class^="icon-"] {
            fill: #fec32d;
            color: #fec32d;
            &:before {
              color: #fec32d;
            }
          }
          &:after {
            background: #fec32d;
          }
        }
      }

      & span {
        font-size: 18px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
        fill: #555555;
        color: #555555;
      }

      & span[class^="icon-"]::before {
        color: #555555;
      }
    }
    & a:hover::after,
    & a.active-toggle::after {
      content: "";
      background: #fec32d;
      border-radius: 5px;
      width: 5px;
      height: 80%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0px, -50%);
    }
    & a:hover,
    & a.active-toggle {
      color: #fec32d;
      // background: rgba(216, 216, 216, 0.098);
      background: #f4f4f4;
      // opacity: 0.2;

      & span,
      & span:hover {
        fill: #fec32d;
        color: #fec32d;
      }

      & span[class^="icon-"]::before {
        color: #fec32d;
      }
    }
  }
  .icon-order-lists svg {
    width: 18px;
  } 
}

// dashboard css starts
.color-33 {
  color: var(--gray-text-color);
}
.dashboard {
  .content-wrapper {
    background: transparent !important;
  }
  .boxTyp3 {
    background: #e5e5e533;
  }
}
.dash-b-wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 1rem 2.56rem;
  min-height: 122px;
  position: relative;
  z-index: 1;
}
.color-grey {
  color: #555555 !important;
}
.dark-grey {
  color: #333333;
}
.color-head {
  color: #555555;
}
.color-blue {
  color: #64a8da;
}
.color-red {
  color: #ff0000;
}
.color-orange {
  color: var(--btn-danger-color);
}
.bor-btm {
  border-bottom: 5px solid;
}
.bor-top {
  border-top: 1px solid #dcdcdc;
}
.bor-orange {
  border-color: var(--btn-danger-color);
}
.bor-blue {
  border-color: #085133;
}
.font-22 {
  font-size: 21px;
  @media (min-width: 1200px) and (max-width: 1320px) {
    font-size: 18px;
  }
}
.font-70 {
  font-size: 70px !important;
}
.font-35 {
  font-size: 35px;
}
.font-30 {
  font-size: 30px;
}
.font-25 {
  font-size: 20px;
}
.font-20 {
  font-size: 20px;
}
.font-19 {
  font-size: 19px;
}
.font-23 {
  font-size: 23px;
}
.h3,
h3 {
  font-size: 1.6rem;
}
.t-value {
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
  margin-bottom: 12px;
}
.p-dec {
  padding: 1rem 1.7rem;
}
.bottom-box {
  position: absolute;
  width: 80%;
  background: #F3F3F3;

  height: 20px;
  left: 50%;
  transform: translate(-50%, -8px);
  border-radius: 50px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05);
  z-index: 0;
}
.opacity-dec {
  opacity: 0.7;
}

// footer css
.footer-txt {
  color: var(--tertiary-color);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  padding-top: 20px;
  a {
    color: var(--tertiary-color);
    font-weight: 500;
  }
}

// customer css
.mainContainer{
  .boxTyp3{
    min-height: calc(100vh - 77px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.CustomerTable {
  .content-wrapper {
    background: transparent !important;
  }
  .boxTyp3 {
    background: #e5e5e533;
  }
  & .searchTxt {
    background: #ffffff;
    border: 0.5px solid #dfdfdf;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    font-size: 14px;
    color: var(--gray-text-color);
    padding-right: 2.8rem;
    padding-left: 1.5rem;
    height: 50px;
    min-width: 330px;
    &::placeholder{
      color: #c2c2c2;
    }
  }
  .searchTxt:focus {
    outline: none;
  }
  & .icon-search {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: var(--light-gray-color);
  }
  .ttl-order-table {
    tr {
      td {
        a {
          color: #555;
        }
      }
    }
  }
  .ReactTable {
    .text-green {
      color: #55a026;
    }
    .text-orange {
      color: #ff782d;
    }
    .text-blue {
      color: var(--blue-color);
    }
    .text-bluish {
      color: #085133;
    }
    .text-red {
      color: #ff6666;
    }
    .text-blue {
      color: var(--blue-color);
    }
    .text-reddish {
      color: #f05c5c;
    }
    .text-blue1{
      color: #187897

    }
    border: none;
    .rt-table {
      border-collapse: separate;
      border-spacing: 0 6px;
    }
    .rt-thead {
      background: #FFF5CD;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02);
      margin-bottom: 7px;
      .rt-th.-sort-asc,
      .rt-td.-sort-asc {
        box-shadow: none;
      }
      .rt-tr {
        text-align: left;
      }
      .rt-th {
        border: none;
        font-size: 18px;
        color: #555555;
        font-weight: normal;
        padding: 12px 10px;
        white-space: pre-wrap;
      }
      .rt-th:focus {
        box-shadow: none;
        outline: none;
      }
      .rt-resizable-header-content:after {
        content: "\e92e";
        font-family: "icomoon" !important;
        color: #999;
        font-size: 16px;
        margin-left: 5px;
        position: relative;
        top: 2px;
      }
      .rt-resizable-header-content {
        overflow: initial;
        text-overflow: unset;
        font-size: 16px;
        color: var(--tertiary-color);
        font-family: "Kanit", sans-serif;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
    .rt-tbody {
      .rt-td {
        font-weight: normal;
        font-size: 16px;
        color: #5f5f5f;
        border: 0;
        vertical-align: middle;
        position: relative;
        padding: 12px 10px;
        word-wrap: break-word;
        border: none;
        max-width: 270px;
        text-overflow: initial;
        white-space: pre-wrap;
        a{
          color: #555;
          align-items: center;
          display: flex;
          gap: 7px;
          font-size: 15px;
        }
      }
      .rt-tr-group {
        border-bottom: none;
        background: #ffffff;
        margin-bottom: 7px;
      }
    }
    .fnt-small {
      th {
        font-size: 20px;
      }
      td {
        font-size: 18px;
      }
    }
    .ttl-order-table {
      [class^="icon-"],
      [class*=" icon-"] {
        vertical-align: middle;
      }
    }
  }
  .table {
    border-collapse: separate;
    border-spacing: 0 6px;
    thead {
      background: #FFF5CD;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02);
    }
    th {
      font-size: 18px;
      color: #555555;
      font-weight: normal;
      border: 0;
      padding: 12px 10px;
    }
    td {
      font-weight: normal;
      font-size: 15px;
      color: #5f5f5f;
      border: 0;
      vertical-align: middle;
      position: relative;
      padding: 12px 10px;
      word-wrap: break-word;
      max-width: 270px;
    }
    tbody {
      tr {
        background: #ffffff;
      }
    }
    .text-green {
      color: #55a026;
    }
    .text-orange {
      color: #ff782d;
    }
    .text-red {
      color: #ff6666;
    }
    .text-blue {
      color: var(--blue-color);
    }
    .text-reddish {
      color: #f05c5c;
    }
  }
}
.block-btn {
  background: #fff2dc;
  border: 1px solid #ffce78;
  box-sizing: border-box;
  border-radius: 15px;
  width: 99px;
  color: #555555;
  font-size: 20px;
}
.global-border-btn {
  box-sizing: border-box;
  border-radius: 15px;
  width: 115px;
  font-size: 18px;
  border: none;
  background: none;
}
.success-btn {
  border: 1px solid #8cd969;
  color: #8cd969;
}
.warning-btn {
  border: 1px solid #ffbe41;
  color: #ffbe41;
}
.in-transit-btn {
  border: 1px solid #fea2b8;
  color: #fea2b8;
}
.delivered-btn {
  border: 1px solid #9cbbff;
  color: #9cbbff;
}
.del-cancel-btn {
  color: #6cf1c1;
  border: 1px solid #6cf1c1;
}
.global-action-btn {
  box-sizing: border-box;
  border-radius: 15px;
  font-size: 20px;
  border: none;
  background: none;
  padding: 0;
}
.blue-btn {
  // background: #E3F8FF;
  color: #5cd8ff;
}
.yellow-btn {
  // background: #FFF4DF;
  color: #ff782d;
}
.red-btn {
  // background: #FFE3DD;
  color: #ff522c;
}
.green-color-btn {
  color: #34ae09;
}

.unblock-btn {
  background: #e4fde4;
  border: 1px solid #9aff9a;
  box-sizing: border-box;
  border-radius: 15px;
  width: 99px;
  color: #555555;
  font-size: 20px;
}
td.text-green .flex-active,
td.text-reddish .flex-active,
td.text-red .flex-active {
  display: flex;
  align-items: center;
  gap: 5px;
  vertical-align: middle;
}
.ab-dot {
  position: static;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  top: 40%;
  left: 10px;
  margin-right: 4px;
  &.ab-dot-new {
    // top: 47%;
  }
}

button:focus {
  outline: none;
}
.green {
  background: #11d200;
}
.red {
  background: #ff6666;
}
.grey {
  background: #d1d1d1;
}
.color-light-grey {
  color: #e1e1e1 !important;
}
table {
  .icon-up-and-down {
    &.color-light-grey {
      color: #999999 !important;
    }
    position: relative;
    top: 2px;
  }
}
.icon-up-and-down {
  font-size: 16px;
  margin-left: 5px;
}
.icon-up-and-down:hover {
  color: #fec32d;
}
.deliva-pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  // padding-right: 180px;
  padding-right: 170px;
  margin-bottom: 0;
  align-items: center;
  li {
    &.page-item {
      line-height: 40px;
      &.da {
        margin-right: -15px;
      }
    }
  }

  .pagination-btn,
  .page-link {
    border: none;
    background: none;
    // padding: 3px 12px;
    padding: 10px 5px;
    border-radius: 50% !important;
    font-size: 18px;
    margin-right: 10px;
    cursor: pointer;
    color: #757575;
    &.active {
      padding: 0 0px;
      width: 41px;
      height: 41px;
      line-height: 40px;
      border-radius: 50%;
      border: 1px solid var(--primary-color-new);
      color: var(--primary-color-new);
      background: var(--white-color);
      text-align: center;
    }
  }
  .rotate-180 {
    -ms-transform: rotate(20deg); /* IE 9 */
    transform: rotate(180deg);
  }
  .control-btn {
    // padding: 7px 10px;
    // border-radius: 50% !important;
    // border: 1px solid #d0d1d8;
    // background: #ffffff;
    // font-size: 13px;
    // color: #d0d1d8;
    padding: 10px 5px;
    width: 40px;
    min-width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;

    border-radius: 50% !important;
    border: 1px solid #555555;
    background: #ffffff;
    font-size: 13px;
    color: #555555;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    & > span {
      font-weight: 600;
    }
  }
  .Table__pageButton--active {
    color: #085133;
    border: 1px solid #085133;
    width: 40px;
    height: 40px;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
  }
}
.table-wrap {
  position: relative;
}
.page-value {
  vertical-align: middle;
  font-size: 16px;
  color: #555555;
  position: absolute;
  right: 15px;
  bottom: 0;
  padding-left: 10px;

  input {
    width: 42px;
    height: 40px;
    text-align: center;
    border: 1px solid #555;
    padding: 5px;
    color: var(--primary-color-new);
    // margin-left: 5px;
    margin-left: 3px;
    font-size: 18px;
  }
}
.color-boader {
  border: 1px solid #085133 !important;
}
.Fs12 {
  font-size: 12px;
}
.bor-btn {
  border: 1px solid var(--primary-color-new);
  padding: 8px 15px;
  background: var(--white-color);
  border-radius: 5px;
  font-size: 15px;
  color: var(--primary-color-new);
  &.bor-blues {
    background: var(--white-color);
    border: 1px solid var(--primary-color-new);
    color: var(--primary-color-new);
    padding: 8px 12px;
  }
}
.bor-da-btn {
  border: 1px solid var(--primary-color-new);
  color: var(--primary-color-new);
  padding: 8px 15px;
  background: var(--white-color);
  border-radius: 5px;
  font-size: 15px;
  // color: #000000;
  &.bor-blues {
    background: var(--white-color);
    border: 1px solid var(--primary-color-new);
    color: var(--primary-color-new);
    padding: 8px 12px;
  }
}
.grey-btn {
  border: 1px solid #dedede;
  color: #989898;
}
// customer details page css
.cust-details-wrap {
  padding: 20px;
  background: #ffffff;
  border: 1px solid var(--primary-color-new);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(255, 164, 80, 0.05);
  border-radius: 15px;
  p.color-grey {
    color: #555 !important;
  }
}
.delivery-agent-bg {
  background: var(--white-color);
  border: 1px solid var(--primary-color-new);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(255, 97, 144, 0.05);
  border-radius: 15px;
}
.delivery-agent-detail-bg {
  background: #ffffff;
  border: 1px solid var(--primary-color-new);
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(254, 195, 45, 0.15);
  border-radius: 15px;
}

.ratings {
  [class^="icon-"],
  [class*=" icon-"] {
    color: #fec32d;
    margin-right: 10px;
  }
}
.detail-list {
  list-style-type: none;
  word-break: break-word;
  &.color-grey {
    color: #555555 !important;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    margin-right: 10px;
  }
  li {
    margin-bottom: 15px;
  }
  li:last-child {
    margin-bottom: 0;
  }
}
.bor-left {
  border-left: 0.5px solid #c7c7c7;
}
.max-width-sm {
  max-width: 135px;
}
.filter-btn {
  position: static;
  background: #ffffff;
  border: 0.5px solid #fec32d;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 0 2px;
}
.filter-btn-wrapper {
  margin-top: 10px;
  position: relative;
  background: #ffffff;
  border: 0.5px solid #dedede;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 10px 20px;
  a {
    color: #fec32d;
  }
  .dropdown-menu.show {
    top: 40px !important;
    right: 0px !important;
    width: 98%;
    left: 11px !important;
    padding: 15px 20px;
    transform: none !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border: none;
  }
  .dropdown-menu.show:before,
  .dropdown-menu.show:after {
    content: "";
    width: 15px;
    height: 15px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #fff;
    position: absolute;
    right: 25px;
    top: -13px;
    // transform: rotate(225deg);
  }
  .dropdown-menu.show:before {
    top: -18px;
    z-index: 0;
    border-bottom: 18px solid;
    border-bottom-color: #e0e0e0;
  }
}
.filter-btn-wrapper:before,
.filter-btn-wrapper:after {
  content: "";
  width: 15px;
  height: 15px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid #fff;
  position: absolute;
  right: 25px;
  top: -13px;
  // transform: rotate(225deg);
}
.filter-btn-wrapper:before {
  top: -18px;
  z-index: 0;
  border-bottom: 18px solid;
  border-bottom-color: #e0e0e0;
}

// Delivery agent profile page css starts
.details-wrap {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 15px 0;
  // min-height: 285px;
}
.colored-wrap {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 40px 60px;
  display: flex;
  flex-wrap: wrap;
  min-height: 280px;
  & .color-grey {
    font-family: Inter !important;
    font-size: 35px;
    font-weight: 600 !important;
    line-height: 42.36px;
    color: #FBFCF4 !important;
  }
  & .dark-grey{
    font-family: Kanit !important;
    font-size: 70px !important;
    font-weight: 500 !important;
    color: #FBFCF4 !important;

  }
  @media (min-width: 1200px) and (max-width:1600px) {
    padding: 20px;
  }
}
.orange-box {
  background: #F1A57A;
}
.blue-box {
  background: #75D4F2;
}
.purple-box {
  background: #A589E0;
}
.yellow-box {
  background: #FF8080;

}

.button-wrapper {
  button {
    margin-right: 25px;
  }
}
.border-radius-btn {
  border-radius: 8px;
}
.pagination {
  flex-wrap: wrap;
}
.orange-text {
  color: var(--btn-danger-color);
}
.modal-content-wrap {
  padding: 20px 50px;
}
.b1-btn {
  background: var(--primary-color-new);
  border: solid 1px var(--primary-color-new);
  // box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 5px 25px;
  color: var(--white-color);
  font-size: 15px;
  border: none;
}
.cancel-btn {
  color: var(--primary-color-new);
  border: 1px solid var(--primary-color-new);
  background: var(--white-color);
  border-radius: 8px;
  box-sizing: border-box;
  // box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.05);
  padding: 4px 19px;
  margin-left: 0;
  font-size: 15px;
}
.user-block-img {
  max-width: 100px;
}
.w-15 {
  width: 15px;
}
.storeProfileBg {
  background: var(--white-color);
  border: 1px solid var(--primary-color-new);
  box-sizing: border-box;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
}
.store-arrow-img {
  max-width: 185px;
}
.hrs-list {
  list-style-type: none;
  padding-left: 5px;
  font-size: 13px;

  li {
    border-bottom: 0.5px solid #c2c2c2;
    padding-bottom: 9px;
    padding-top: 9px;
  }
}
.storeCol {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  color: #fff;
  padding: 0 15px;

  .row {
    height: 110px;
  }
  p {
    margin-bottom: 0;
  }
}
.bg1 {
  background: #ffb49c;
}
.bg2 {
  background: #97e6ff;
}
.bg3 {
  background: #debdff;
}
.bg-gray{
  background: $mid-gray;
}
.shadow-0{
  box-shadow: initial;
}
.rounded-4{
  border-radius: 4px;
}
.numberWrap {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 15px;
  padding: 6px 0;
  text-align: center;
  font-size: 28px;
}
.search-wrap {
  background: #fbfbfb;
  border: 0.5px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  padding: 5px 8px;
  padding-right: 0;
  justify-content: end;
  max-width: 70%;

  input {
    border: 0;
    padding-left: 25px;
    padding-right: 5px;
    font-size: 14px;
    background: transparent;
  }
  .search1 {
    padding-left: 35px;
  }

  .search-inner-wrap:last-child {
    border-left: 1px solid #d1d1d1;
  }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border: none;
    outline: 0;
    box-shadow: none;
  }
  .search-inner-wrap {
    width: 45%;
    position: relative;
  }
  .search-inner-wrap:first-child {
    border-right: 1px solid #d1d1d1;
  }
  .search-inner-wrap:nth-child(2) {
    margin-left: 1px;
  }
  .icon-search,
  .icon-close1 {
    position: absolute;
    left: 0.4rem;
    top: 50%;
    color: var(--light-gray-color);
    transform: translate(0px, -50%);
    z-index: 3;
    right: auto;
  }
  .icon-close1 {
    right: 1.3rem;
    left: auto;
    font-size: 12px;
  }
  .find-btn {
    color: var(--white-color);
    background: var(--primary-color-new);
    border: 0.5px solid var(--primary-color-new);
    box-sizing: border-box;
    border-radius: 18px;
    padding: 0px 15px;
    font-size: 15px;
    margin-left: 8px;
    margin-right: 8px;
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: #c2c2c2;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c2c2c2;
  }

  ::placeholder {
    color: #c2c2c2;
  }
}

.crystal-wrap {
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 20px;
  .image-wrap {
    ul {
      li {
        margin-top: 5px;
        margin-bottom: 5px;
        & > img {
          &.img-wraps {
            width: 163px;
            height: 163px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
.color-yellow {
  color: #fec32d;
}
.heading-color {
  color: #333333;
}
.color-blue {
  color: #085133 !important;
}
// orderdetails timeline starts
.main-timeline-section {
  position: relative;
  width: 100%;
  margin: auto;
  height: 230px;
}

.main-timeline-section .conference-center-line {
  position: absolute;
  width: 75%;
  left: 12.5%;
  height: 1px;
  top: 33%;
  transform: translateY(-50%);
  background: #085133;
}
.timeline-article {
  width: 25%;
  position: relative;
  min-height: 220px;
  float: left;
  font-size: 15px;
}
.timeline-article .content-date {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  text-align: center;
  color: #999999;
}
.timeline-article .meta-date {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  // width: 58px;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  // background: var(--white-color);

  //   background:#FEC32D;
  //   border:1px solid #F2635F;

  .tick:after {
    content: "\e92c";
    font-family: "icomoon" !important;
    color: #085133;
    font-size: 2.5rem;
    line-height: 1;
    // margin-left: 10px;
    margin-left: 0;
    background: #fff;
    border-radius: 50%;
  }

  .step {
    border: 1px solid #085133;
    color: #085133;
    width: 42px;
    height: 42px;
    font-size: 2rem;
    text-align: center;
    display: block;
    border-radius: 50%;
    background: #fff;
    // font-family: 'Nunito', sans-serif !important;
    line-height: 40px;
    // margin: auto;
    // margin: 3px auto;
  }
  .step.focused {
    background-color: #085133;
    color: #fff;
  }
}

.timeline-article .content-box {
  width: 180px;
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: 10px;
  text-align: center;
  color: #085133;
}
.greenish-blue {
  color: #333333;
}
.timeline-article:nth-child(3) .content-box,
.timeline-article:nth-child(4) .content-date {
  color: #959292;
}
.timeline-article-bottom .content-date {
  top: 82%;
}
.timeline-article-bottom .content-box {
  top: 0%;
}
.custom-hr {
  // background: #085133;
  border-top: 1px dashed #085133;
  width: 100%;
}
.location-btn {
  background-image: url(./assets/images/RadioIcon.svg);
  width: 20px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: block;
  float: left;
  margin-right: 8px;
}
.block-wrap {
  background: #fffefb;
  border: 1px solid var(--primary-color-new);
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}
.block-header {
  padding: 20px;
  background: $light-pink;
  border-radius: 15px;
}
.boxTyp3 {
  .block-wrap {
    & > .block-header {
      border-radius: 15px 15px 0px 0px;
    }
  }
}
.min-w-80 {
  min-width: 80px;
}
.block-content {
  padding: 20px;
}
.package-link {
  color: #fec32d;
  text-decoration-line: underline;
}
.show-btn {
  border: 0.5px solid #9c9c9c;
  box-sizing: border-box;
  border-radius: 5px;
  color: #9c9c9c;
  padding: 7px 20px;
  background-color: #fff;
}
.destination-icon {
  background-image: url(./assets/images/DestinationIcon.svg);
  width: 20px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: block;
  float: left;
  margin-right: 8px;
}
.cancellation-box {
  width: 40%;
  background: var(--white-color);
  border: 1px solid var(--primary-color-new);
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  text-align: center;
  padding: 40px 20px;
  margin: 80px auto 20px;
}
.image-wrap {
  background-color: #FFF5CD;
  border: 0;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 15px;
  text-align: left;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23085133FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");


  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  li {
    display: inline-block;
    margin-right: 10px;
  }
}
.yellow-text-btn {
  border: none;
  background: none;
  color: #fec32d;
  font-size: 20px;
}
.red-text-btn {
  border: none;
  background: none;
  color: #ff2626;
  font-size: 20px;
}
.yellow-hr {
  background-color: var(--primary-color-new);
}
.reject-btn {
  background: var(--white-color);
  border: solid 1px var(--primary-color-new);
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 6px 28px;
  border: none;
  color: var(--primary-color-new);
  margin-right: 20px;
}
.approve-btn {
  padding: 6px 28px;
  background: var(--primary-color-new);
  border: solid 1px var(--primary-color-new);
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: none;
  color: var(--white-color);
}

//  radio buttons custom
.deliva-radio [type="checkbox"]:checked,
.deliva-radio [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.deliva-radio [type="checkbox"]:checked + label,
.deliva-radio [type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: var(--green-color);
}
.deliva-radio [type="checkbox"]:not(:checked) + label {
  color: #666;
}
.deliva-radio [type="checkbox"]:checked + label:before,
.deliva-radio [type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;

  border-radius: 100%;
  background: var(--green-color);
}
.deliva-radio [type="checkbox"]:not(:checked) + label:before {
  border: 1px solid #989898;
  background: #fff;
}

.deliva-radio [type="checkbox"]:checked + label:after,
.deliva-radio [type="checkbox"]:not(:checked) + label:after {
  content: "";
  position: absolute;
  left: 9px;
  top: 5px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.deliva-radio [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
}
.deliva-radio [type="checkbox"]:checked + label:after {
  opacity: 1;
}

// admin profile starts
.Admin-form-wrap {
  background: rgba(254, 195, 45, 0.05);
  border-radius: 15px;
  padding: 40px;
}
.admin-form-inner-wrap {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  position: relative;
}
.account-detail-wrap {
  background: rgba(254, 195, 45, 0.02);
  padding: 50px 10px;
  // text-align: center;
}

.details-image-wrap {
  text-align: center;
  padding: 20px 0;
}
.profile-pic {
  border-radius: 50%;
  border: 4px solid #fff1ce;
  box-sizing: border-box;
}
.admin-name {
  color: #333333;
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  text-transform: uppercase;
}
.edit-btn {
  position: absolute;
  top: 20px;
  right: 30px;
  background: rgba(255, 204, 77, 0.47);
  padding: 6px 14px;
  border-radius: 50%;
  font-size: 25px;
  .icon-edit-ico:before {
    color: #fff;
  }
}
.ln-ht {
  line-height: 50px;
}
.loaderModal {
  .modal-content {
    background-color: transparent;
    border: none;
    img {
      width: 100px;
      display: block;
      text-align: center;
      margin: auto;
    }
  }
}
.loaderModal {
  .modal-content {
    background-color: transparent;
    border: none;
    img {
      width: 100px;
      display: block;
      text-align: center;
      margin: auto;
    }
  }
}
.text-danger {
  color: red !important;
}
.error {
  // top: 63px;
  top: 50%;
  font-size: 0.825rem;
  left: 20px;
  position: absolute;
  color: red;
  margin-top: 35px;

  &.login {
    top: 63px;
  }
}
.reset-password {
  margin-bottom: 2rem;
}
.edit-btn1 {
  position: absolute;
  bottom: 25px;
  left: 55%;
  background: #085133;
  padding: 5px 8px;
  border-radius: 50%;
  .icon-edit-ico:before {
    color: #fff;
  }
}

// notification starts
.notification-block {
  position: relative;
  background: var(--white-color);
  border: 1px solid var(--primary-color-new);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02);
  border-radius: 15px;
  padding: 15px 20px;
}
.time-label {
  color: #d1d1d1;
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 18px;
}
.border-left-blue {
  border-left: 7px solid var(--primary-color-new);
}
.border-left-orange {
  border-left: 7px solid #ffd6c0;
}
.border-left-purple {
  border-left: 7px solid #f0d8ff;
}
.border-left-green {
  border-left: 7px solid #d6ffd6;
}
.border-left-pink {
  border-left: 7px solid #ffdee4;
}

// accounts start
.cards-wrap {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 10px 0 20px;
  position: relative;
  text-align: center;
}
.add-btn-btm {
  background: #ffffff;
  border: 0.5px solid #fec32d;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  color: #fec32d !important;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 28px;
  line-height: 34px;
  cursor: pointer;
}
.text-greenish {
  padding: 6px 18px;
  background: #eeffeb;
  border-radius: 15px;
  color: #3eed23;
}
.text-redish {
  padding: 6px 18px;
  background: #fff5f7;
  border-radius: 15px;
  color: #ff5f7b;
}
.cards-wrap a.card-img-wrapper {
  position: relative;
  display: inline-block;
  padding: 0 10px;
}
.cards-wrap a.card-img-wrapper.active:after {
  content: "";
  position: absolute;
  width: 9px;
  height: 58%;
  background: #fec32d;
  border-radius: 20px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.total-bal-wrap {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 15px 18px 25px;
}
.total-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.font-55 {
  font-size: 45px;
}
.deposit-button {
  background: #fff2cf;
  border: 0.5px solid #fec32d;
  box-sizing: border-box;
  border-radius: 15px;
  color: #fec32d;
  padding: 8px 20px;
  font-size: 18px;
}
.withdraw-btn {
  background: #f4f4f4;
  border: 0.5px solid #d1d1d1;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 8px 20px;
  color: #d1d1d1;
  font-size: 18px;
}
.history-wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 16px;
}
.color-light-grey {
  color: #d1d1d1;
}
.recent-block {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 15px 20px;
  display: inline-block;
  width: 100%;
}
.border-left-purple-thin {
  border-left: 3px solid #ffc5fd;
}
.border-left-green-thin {
  border-left: 3px solid #08b041;
}
.border-left-red-thin {
  border-left: 3px solid #ff0000;
}
.border-left-orange-thin {
  border-left: 3px solid #ffc670;
}
.border-left-blue-thin {
  border-left: 3px solid #79bfff;
}
.recent-time-label {
  color: #d1d1d1;
  font-size: 18px;
  float: right;
}
.date-wrap {
  float: left;
  border: 0.5px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 2px 8px;
  text-align: center;
  margin-right: 20px;
  p {
    line-height: 25px;
  }
}
.name-wrap {
  width: 50%;
  float: left;
}
.right-box {
  font-size: 28px;
  line-height: 58px;
}
.btn-wrapper1,
.btn-wrapper {
  float: left;
  transform: rotate(135deg);
  color: #3eed23;
  font-size: 22px;
  line-height: 58px;
  margin-right: 8px;
}
.btn-wrapper {
  transform: rotate(-60deg);
  color: #ff5f7b;
}
.sm-text {
  font-size: 15px;
}
.line-height {
  line-height: 50px;
}
.search-history {
  background-color: #fcfcfc;
  border-radius: 30px;
  font-size: 18px;
  color: #aeaeae;
  padding-right: 2.8rem;
  padding-left: 1.5rem;
  height: 50px;
  border: none;
}

.search-history:focus {
  color: #d1d1d1;
  box-shadow: 0 0 0 0.07rem rgba(161, 161, 161, 0.25);
}

.search-history + .icon-search {
  position: absolute;
  right: 2rem;
  top: 0.8rem;
  color: var(--light-gray-color);
  font-size: 22px;
}
.swap-wrap {
  padding: 5px 7px;
  background: #f7f7f7;
  border-radius: 5px;
  line-height: 15px;
  font-size: 15px;
  display: inline-block;
}
.graph-right-wrap {
  float: right;
  width: 65%;
  display: flex;
  justify-content: space-around;
}
.graph-head1,
.graph-head2 {
  position: relative;
}
.graph-head1:before,
.graph-head2:before {
  width: 10px;
  height: 10px;
  position: absolute;
  content: "";
  background: #085133;
  left: -15px;
  top: 7px;
  border-radius: 50%;
}
.graph-head2:before {
  background: #fec32d;
}
.login-input::placeholder {
  color: #999999;
}
.payout-cards {
  position: relative;
  text-align: center;
  height: 440px;
}
.card-img-wrapper1,
.card-img-wrapper2,
.card-img-wrapper3 {
  position: absolute;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.card-img-wrapper1 {
  top: 10px;
}
.card-img-wrapper2 {
  top: 85px;
}
.card-img-wrapper3 {
  top: 162px;
}
// setting starts
.setting-block {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 15px 20px;
  h4 {
    font-size: 1.4rem;
  }
}

/* toggle in label designing */
.toggle {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 25px;
  background-color: #ebebeb;
  border-radius: 30px;
}

/* After slide changes */
.toggle:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffffff;
  top: 3px;
  left: 5px;
  transition: all 0.5s;
}

/* Toggle text */
// p {
//     font-family: Arial, Helvetica, sans-serif;
//     font-weight: bold;
// }

/* Checkbox cheked effect */
.checkbox:checked + .toggle::after {
  left: 31px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

/* Checkbox cheked toggle label bg color */
.checkbox:checked + .toggle {
  background-color: #fec32d;
}

/* Checkbox vanished */
.checkbox {
  display: none;
}
.knob:before,
.knob:after {
  position: absolute;
  top: 1px;
  width: 20px;
  height: 10px;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  border-radius: 2px;
  transition: 0.3s ease all;
  color: #fff;
}
.knob:before {
  content: "ON";
  left: 4px;
}
.knob:after {
  content: "OFF";
  right: 8px;
}
.ico-wrapper {
  font-size: 25px;
  line-height: 55px;
  text-align: center;
  color: #ffe6a5;
}
.logout-btn {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  color: #c4c4c4;
  padding: 5px 10px;
}
.payout-wrapper {
  background-image: url("./assets/images/PayoutBg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0 15px;
}
.deliva-popup {
  border-radius: 15px;
  select,
  textarea {
    background: #ffffff;
    border: 0.5px solid #dedede;
    box-sizing: border-box;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding-bottom: 25px;
  }
}
.min-char {
  position: absolute;
  bottom: 5px;
  right: 15px;
  color: #d1d1d1;
  font-size: 13px;
}
.reject-img {
  max-width: 65px;
}
.sidebar .sidebarNav a {
  text-transform: uppercase;
}
.dropdown-container {
  display: none;
  background-color: #fff;
  padding-left: 15px;
}
.sidebar .sidebarNav a.active + .dropdown-container {
  display: block;
}
.CustomerTable .table td {
  max-width: 150px;
}
select#formBasicSelect {
  width: 80%;
  float: right;
  height: 50px;
  border: solid 1px var(--primary-color-new);
  color: var(--primary-color-new);
  box-shadow: none;
  outline: 0;
}
@media (max-width: 1460px) {
  .search-wrap .search-inner-wrap {
    width: 43%;
  }
}
@media (max-width: 1200px) {
  .search-wrap .search-inner-wrap {
    width: 40%;
  }
  .search-wrap  .find-btn{
    width: 17%;
  }
}
@media (min-width: 1200px) {
  .min-ht {
    min-height: 145px;
  }
}
@media (max-width: 1385px) {
  .CustomerTable .table th {
    font-size: 19px;
    padding: 12px 7px;
  }
  .CustomerTable .table td {
    font-size: 17px;
    padding: 14px 8px;
  }
}
@media (max-width: 1300px) {
  .CustomerTable .table th {
    font-size: 16px;
    padding: 12px 5px;
  }
  .CustomerTable .table td {
    font-size: 15px;
    padding: 14px 6px;
  }
}
@media (max-width: 1280px) {
  .dash-b-wrapper {
    min-height: 165px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .font-23 {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  .dash-b-wrapper {
    margin-bottom: 20px;
  }
  .topbar .dropdown-menu:before {
    content: "";
    right: 30px;
  }
  .topbar .dropdown-menu {
    right: -25px !important;
  }
}
@media (max-width: 768px) {
  .topbar .dropdown-menu:before {
    content: "";
    right: 35px;
  }
  .topbar .dropdown-menu {
    top: 50px !important;
  }
  .dash-b-wrapper {
    margin-bottom: 20px;
    text-align: center;
  }
  .bottom-box {
    position: absolute;
    transform: translate(-50%, 0px);
    top: 86%;
  }
}
.admin-wrap {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  .admin-head {
    background: rgba(255, 205, 5, 0.2);
    // border-left: 3px solid #FFF5CD;
    
    padding: 8px 15px;
    color: #000;
    &:first-child{
      border-radius: 15px 15px  0 0;
    }

  }

  .login-input:disabled {
    background: #efefef;
    box-shadow: inherit;
  } 
  
  .admin-body {
    padding: 35px 25px;
  }
  .login-input {
    background: #ffffff;
    border: 0.5px solid #dedede;
    box-sizing: border-box;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    height: 50px;
  }
}
.active-btn,
.inactive-btn {
  color: var(--primary-color-new);
  border: 1px solid var(--primary-color-new);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 3px 20px;
  background: none;
  margin-right: 18px;
}
.inactive-btn {
  color: #7e7e7e;
  border: 1px solid #7e7e7e;
}
.bg-none {
  background: none !important;
}
.color-black {
  color: #000000;
}
.color-greyish-black {
  color: #7e7e7e;
}
.Terms-wrap {
  .tc-wrap {
    .btn-link:hover {
      color: #202020;
      text-decoration: none;
    }
    .card {
      background: #ffffff;
      // border: 0.2px solid #C2C2C2;
      box-sizing: border-box;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .card-header {
      padding: 0.75rem 0;
      margin: 0 0.6rem;
      margin-bottom: -1px;
      background-color: #f1f1f108;
    }
    .card-body {
      color: #959292;
    }
    hr {
      display: none;
    }
    .collapse.show + .card-header hr {
      display: block;
    }
  }
}
.w-97 {
  width: 97%;
}
select.login-input {
  -webkit-appearance: none;
}
span.icon-next.down {
  position: absolute;
  right: 25px;
  top: 20px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  display: inline-block;
  color: #d1d1d1;
}

@media (min-width: 768px) {
  .mxw-90 {
    max-width: 94%;
    position: relative;
  }
}

@media (max-width: 690px) {
  .topbar .dropdown-menu {
    width: 412px;
  }
}
@media (max-width: 580px) {
  .topbar .dropdown-menu {
    width: 386px;
    right: -100px !important;
  }
  .topbar .dropdown-menu:before {
    content: "";
    right: 111px;
  }
}
@media (max-width: 480px) {
  .topbar .dropdown-menu {
    width: 308px;
    right: -40px !important;
  }
  .topbar .dropdown-menu:before {
    content: "";
    right: 48px;
  }
}
@media (max-width: 340px) {
  .topbar .dropdown-menu {
    width: 285px;
    right: -152px !important;
  }
  .topbar .dropdown-menu:before {
    content: "";
    right: 162px;
  }
}
//--------css added by pj start----------
.border-none {
  border: none;
}
.background-none {
  background: none;
}
$lightGreen: #66bb00;
.lightGreenText {
  color: $lightGreen;
}
.blueBg {
  background-color: var(--primary-color-new);
}
.details-wrap {
  .col-sm-5 {
    @media (min-width: 1200px) {
      padding-left: 0;
    }
  }
  h4.color-grey {
    font-size: 17px !important;
  }
}
.whiteText {
  color: #fff;
}
.smallWrapImg {
  padding: 20px 20px;
  .imgOuterDiv {
    max-width: 257px;
    height: auto;
    border: 0.5px solid #dddddd4d;
    box-shadow: 0px 0px 1px #dddddd47;
    img {
      max-width: 100%;
      height: auto;
      max-height: 182px;
      max-width: 257px;
    }
  }
}
.pr-180p {
  padding-right: 180px !important;
}
.pr-170p {
  padding-right: 170px !important;
}
.pr-175p {
  padding-right: 175px !important;
}
.totalSumbittedBidsTable {
  .rt-thead {
    .rt-th:nth-child(3) {
      width: 120px !important;
    }
  }
    min-height: 173px!important;
    .rt-noData{
      width: 100%;
      text-align: center;
      padding: 12px!important;
      top:45%!important
    }
  
}
.deliveryAgentTable{
  min-height: 173px!important;
  .rt-noData{
    width: 100%;
    text-align: center;
    margin-top: -2px!important;
    padding: 12px!important;
    top: 46%!important;
  }
}
.inTransitOrderTable {
  .rt-thead {
    .rt-th:last-child {
      .rt-resizable-header-content::after {
        content: none;
      }
    }
  }
}
.headerDropdown2 {
  text-transform: uppercase;
  .btn {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
    &::after {
      display: none;
    }
  }
  .dropdown-menu {
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate(-415.2px, 68.8px);
    width: 100px;
    right: 0px !important;
    border-radius: 0px;
    &::before {
      content: "";
      width: 15px;
      height: 15px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 20px solid var(--light-arrow-color);
      position: absolute;
      right: 23px;
      top: -20px;
    }
    a {
      color: #555555;
      &:hover {
        color: var(--primary-color-new);
      }
      &:focus {
        color: var(--primary-color-new);
      }
    }
    .dropdown-item {
      span {
        padding-right: 10px;
      }
    }
  }
}
.notificationCount {
    background: red;
    font-size: 10px;
    font-weight: 600;
    border-radius: 20px;
    padding: 4px;
    min-width: 18px;
    height: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 20px;
    top: 0px;
}
.underline {
  text-decoration: underline;
}
.color-green {
  color: #589529;
}
.pickup-title {
  // font-family: Nunito;
  font-size: 30px;
  font-weight: 400;
  line-height: initial;
  letter-spacing: 0.20000000298023224px;
  margin-bottom: 0;
}
.pickup-text {
  // font-family: Nunito;
  font-size: 35px;
  font-weight: 400;
  line-height: initial;
  letter-spacing: 0.20000000298023224px;
}
.packageName {
  margin-bottom: 0.5rem;
}
.box-reason {
  background: #fff100;
  border-radius: 9px;
  padding: 5px;
  box-shadow: 1px 1px 4px 0px grey;
}
//-------css added by pj end------------
.login-validation {
  .new-error {
    top: 55%;
  }
}
.details-wrap {
  p {
    color: $dark-gray;
  }
}
.border-box-images {
  border: solid 1px $light-gray;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  max-width: 170px;
  justify-content: space-between;
  .img-inner-box {
    width: 70px;
    border-radius: 4px;
    max-height: 50px;
    overflow: hidden;
    display: flex;
    & > img {
      width: 100%;
      height: auto;
    }
  }
}
.ratings {
  display: flex;
  align-items: center;
  & > span {
    & > img {
      margin-right: 5px;
      max-width: 16px;
    }
    &:last-child {
      line-height: initial;
      margin-top: 5px;
      margin-left: 5px;
      font-size: 20px;
    }
  }
}
.order-list-new-table {
  .custom-column-ui {
    & > .col-lg-2 {
      @media (min-width: 992px) {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }
    .form-group {
      display: flex;
      justify-content: flex-end;
      #formBasicSelect {
        width: 190px;
        &:focus {
          box-shadow: none;
          border: 1px solid $new-border-color;
        }
      }
    }
  }
}
.cust-details-wrap {
  .d-flex.justify-content-center {
    & > img.max-width-sm {
      min-width: 135px;
      min-height: 135px;
      max-height: 135px;
      object-fit: cover;
      object-position: top;
    }
  }
}
.CustomerTable {
  .ReactTable {
    .rt-thead {
      .rt-th {
        @media (min-width: 1200px) {
          flex: 1 !important;
        }
      }
    }
  }
}
.crystal-wrap {
  .cancellation-box {
    width: 100%;
    max-width: 600px;
    background: $warning-light;
    border: 1px solid $warning-dark;
    .box-reason {
      background: none;
      border-radius: 0;
      padding: 0;
      box-shadow: none;
    }
  }
}
.topbar {
  .notification-sections {
    button {
      &.dropdown-toggle {
        outline: 0;
        &:hover,
        &:focus {
          box-shadow: none !important;
        }
      }
    }
    .dropdown-head {
      background: var(--secondary-color);
      color: var(--tertiary-color);
      padding: 14px 27px;
      font-family: Inter;
      font-size: 22px;
      font-weight: 500;
      line-height: 26.63px;

    }
    .dropdown-menu {
      width: 450px;
      top: 55px !important;
      &::before {
        border-bottom: 25px solid var(--secondary-color);
        top: -20px;
      }
    }
    ul.notification-list {
      padding: 0 25px 0 35px;
      max-height: 370px;
      overflow: auto;
      .dropdown-item {
        position: relative;
        border-bottom: solid 1px $light-gray-two;
        padding-left: 0;
        padding-right: 0;
        padding-top: 10px;
        span {
          &.time-label {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        h4 {
          white-space: normal;
          font-size: 18px;
          font-weight: 300;
          color: $black-gray;
        }
        h6 {
          white-space: normal;
        }
      }
    }
    .viewAll{
      font-size: 15px;
      font-weight: 500;
      line-height: 22.43px;
      display: block;
      text-align: center;
      text-transform: uppercase;
      color: var(--primary-color-new);
      padding: 14px 0;
      cursor: pointer;
      filter: brightness(100px);
      &:hover{
        color: var(--secondary-color);
      }
    }
    .dropdown-footer {
      font-size: 14px;
      font-weight: 600;
    }
    &.show.dropdown .dark-grey {
      color: var( --secondary-color);
    }
  }
}
.dot-cicles {
  display: block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  left: -20px;
  top: 15px;
  &.dot-grays {
    background: var(--dark-gray-color);
  }
  &.dot-yellow {
    background: $yellow-color;
  }
}
.pointer {
  cursor: pointer;
}
.conference-timeline-content {
  .timeline-article {
    @media (min-width: 1200px) {
      &.timeline-article-top {
        .content-date {
          top: 10%;
          & > span {
            white-space: nowrap;
          }
        }
        .content-box {
          padding: 0;
        }
      }
      .content-date {
        top: 75%;
        & > span {
          // font-family: 'Nunito', sans-serif;
        }
      }
      &:last-child {
        &.timeline-article-bottom {
          .content-date {
            top: 65%;
          }
        }
      }
    }
  }
}
.transfer-details {
  .transfer-box {
    background: $white-color;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 0;
    padding: 10px 15px 0;
    button {
      &.active-btn {
        &.cancel-btns {
          background: transparent;
          border: solid 1px $primary-color;
          color: $primary-color;
          font-weight: 600;
          box-shadow: none !important;
        }
      }
      &.b1-btn {
        background: $primary-color !important;
        border: solid 1px $primary-color !important;
        color: $white-color;
        font-weight: 600;
        box-shadow: none !important;
        padding: 5px 20px 5px 18px;
      }
    }
  }
  .request-user-box {
    & > div {
      .col {
        .col-listing {
          h3 {
            font-size: 15px;
            font-weight: 400;
          }
          p {
            font-size: 14px;
            font-weight: 400;
          }
        }
        &:first-child {
          @media (min-width: 1200px) {
            flex: 120px;
            max-width: 120px;
          }
        }
        &:nth-child(2) {
          @media (min-width: 1200px) {
            flex: 190px;
            max-width: 190px;
          }
        }
        &:nth-child(5) {
          @media (min-width: 1200px) {
            flex: 190px;
            max-width: 190px;
          }
        }
      }
    }
  }
  .transfer-table {
    table {
      thead {
        background: $gray-color-new;
        tr {
          th {
            padding: 12px 15px;
            font-size: 14px;
            color: $black-color;
            font-weight: 400;
            &:last-child {
              text-align: right;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 7px 15px 12px;
            font-size: 14px;
            color: $dark-gray;
            font-weight: 600;
            border-bottom: solid 1px $border-light;
            .active-btn {
              &.transfer-btn {
                background-color: $green-btn;
                border: solid 1px $green-btn;
                color: $white-color;
                font-weight: 600;
                box-shadow: none !important;
              }
            }
            p {
              font-size: 16px;
              font-weight: 600;
            }
            &:last-child {
              text-align: right;
            }
          }
          &:nth-last-child(2) {
            td {
              border-bottom: solid 1px $black-color;
            }
          }
          &:last-child {
            td {
              border-bottom: solid 1px $black-color;
            }
          }
        }
      }
    }
  }
}
.order-list-new-table {
  table {
    thead {
      tr {
        th {
          font-size: 15px !important;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 15px !important;
          &:last-child {
            min-width: 100px;
          }
        }
      }
    }
  }
}
.sidebar {
  .sidebarNav {
    a {
      .icon-ManageAdmin1 {
        svg {
          path {
            fill: $dark-gray;
          }
        }
      }
      .icon-order-lists {
        svg {
          path {
            fill: $dark-gray;
          }
        }
      }
      &.active {
        .icon-order-lists {
          svg {
            path {
              fill: $yellow-color;
            }
          }
        }
      }
      &:hover {
        svg {
          path {
            fill: $yellow-color;
          }
        }
      }
    }
  }
}
.order-title {
  font-size: 14px !important;
  font-style: normal;
  text-transform: uppercase;
  // font-family: 'Nunito-Regular';
  margin-left: 10px;
  display: block;
}
.order-boxlist {
  .table-responsive {
    margin-bottom: 10px;
  }
  table {
    margin-bottom: 0;
    thead {
      tr {
        th {
          white-space: nowrap;
        }
      }
    }
    tbody {
      tr {
        td {
          white-space: normal;
        }
      }
    }
  }
}
.border-light {
  border: solid 1px $border-light;
}
.request-user-box {
  background-color: $white-color;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  .col-listing {
    h3 {
      font-size: 18px;
      font-weight: 400;
      color: $darklight-gray;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: $dark-gray;
      margin-bottom: 0;
    }
  }
}
.line-clamp2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card-layout-update {
  .details-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .details-image-wrap {
      .imgOuterDiv {
        max-height: 140px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        border: none;
        & > img {
          width: 100%;
          height: auto;
        }
      }
    }
    @media (min-width: 992px) {
      min-height: 336px;
    }
    .deliva-radio {
      & > label {
        line-height: 27px;
      }
    }
    .deliva-radio {
      .error {
        top: 88%;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
  }
}
.pwd-icons {
  position: absolute;
  right: 14px;
  top: 16px;
}
button {
  &.info-btns {
    border: 1px solid $primary-color;
    color: $primary-color;
    font-weight: 500;
  }
}
.local-delivery {
  .conference-timeline-content {
    display: flex;
    justify-content: space-between;
  }
}
.details-wrap {
  &.optional-hrs {
    .col-sm-5 {
      @media (min-width: 1200px) {
        padding-left: 15px;
      }
    }
  }
}
.min-30 {
  min-width: 30px;
}
.ml-space3 {
  @media (min-width: 1200px) {
    margin-left: -3px;
  }
}
.table-nowrap-data {
  .rt-thead {
    .rt-resizable-header {
      .rt-resizable-header-content {
        font-size: 15px;
        white-space: nowrap;
      }
    }
  }
}
.process-list {
  border-radius: 10px;
  background: $blue-light;
  padding: 15px 20px;
  color: $dark-gray;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 18px;
  &:last-child {
    margin-bottom: 0;
  }
  input {
    border-radius: 10px;
    border: 0.5px solid $dark-lowgray;
    background: $white-color;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    min-height: 50px;
    padding: 15px;
    font-size: 20px;
    max-width: 300px;
    &:hover,
    &:focus {
      outline: 0;
      box-shadow: none;
      border: 0.5px solid $dark-lowgray;
    }
  }
  .btn-outline {
    background: transparent !important;
    border-radius: 15px;
    border: 1px solid $primary-color !important;
    color: $primary-color !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    padding: 0 20px;
    line-height: 41px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    box-shadow: none !important;
    &:hover {
      box-shadow: none !important;
      background: $white-color !important;
    }
  }

  .tooltip-icon{
    margin-left: 10px!important;
  }
}
.paradate {
  font-size: 20px;
  font-weight: 500;
  color: $dark-gray;
}
.text-info {
  color: var(--blue-color) !important;
}
.minw-80 {
  min-width: 80px;
}
.CustomerTable {
  .order-boxlist {
    .table {
      th {
        min-width: 150px;
      }
    }
  }
}
@media (min-width: 1200px) {
  ul.hrs-list {
    li {
      .col-sm-7 {
        padding-left: 0;
      }
    }
  }
}
.page-val {
  right: 30px;
  bottom: 18px;
}
.total-bid-table {
  .ReactTable {
    .rt-th.rt-resizable-header {
      font-size: 17px;
      &:nth-child(3) {
        min-width: 190px;
        white-space: nowrap;
      }
    }
    .rt-tbody {
      .rt-td {
        &:nth-child(3) {
          min-width: 190px;
          white-space: normal;
        }
      }
    }
  }
}

.open-delivery-table {
  .ReactTable {
    .rt-th.rt-resizable-header {
      font-size: 17px;
      &:nth-child(4),
      &:nth-child(6),
      &:nth-child(5),
      &:nth-child(3) {
        min-width: 180px;
        white-space: nowrap;
      }
    }
    .rt-tbody {
      .rt-td {
        &:nth-child(4),
        &:nth-child(6),
        &:nth-child(5),
        &:nth-child(3) {
          min-width: 180px;
        }
      }
    }
  }
}

.statistics-table {
  .ReactTable {
    .rt-th.rt-resizable-header {
      font-size: 17px;
      &:nth-child(6),
      &:nth-child(1) {
        min-width: 180px;
        white-space: nowrap;
      }
    }
    .rt-tbody {
      .rt-td {
        &:nth-child(6),
        &:nth-child(1) {
          min-width: 180px;
          white-space: nowrap;
        }
      }
    }
  }
}
@media (max-width: 1540px) {
  .delivery-tot-order {
    .boxTyp3 {
      .table-wrap {
        .ReactTable {
          .rt-table {
            .rt-thead {
              .rt-tr {
                .rt-th {
                  &:nth-child(4) {
                    min-width: 220px;
                  }
                }
              }
            }
            .rt-tbody {
              .rt-tr-group {
                .rt-tr {
                  .rt-td {
                    &:nth-child(4) {
                      min-width: 220px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .table-wrap {
    &.delivery-request-ordertable {
      .ReactTable {
        .rt-table {
          .rt-thead {
            .rt-tr {
              .rt-th {
                &:first-child {
                  min-width: 190px;
                }
              }
            }
          }
          .rt-tbody {
            .rt-tr-group {
              .rt-tr {
                .rt-td {
                  &:first-child {
                    min-width: 190px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  .table-wrap {
    .ReactTable {
      .rt-table {
        .rt-thead {
          .rt-tr {
            .rt-th {
              .rt-resizable-header-content {
                font-size: 15px;
              }
            }
          }
        }
        .rt-tbody {
          .rt-tr-group {
            .rt-tr {
              .rt-td {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
  .CustomerTable {
    table {
      &.table {
        thead {
          tr {
            th {
              font-size: 15px;
            }
          }
        }
        tbody {
          tr {
            td {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1320px) {
  .table-wrap {
    .ReactTable {
      .rt-table {
        .rt-thead {
          .rt-tr {
            .rt-th {
              .rt-resizable-header-content {
                font-size: 14px;
                text-wrap: auto;
              }
            }
          }
        }
        .rt-tbody {
          .rt-tr-group {
            .rt-tr {
              .rt-td {
                font-size: 14px;
                text-wrap: auto;
              }
            }
          }
        }
      }
    }
  }
}
.custom-container {
  max-width: 850px;
  margin: auto;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.set-img-top {
  top: -10px;
}
.map-ico-user {
  min-width: 18px;
  margin-top: 5px;
  font-size: 20px;
}
table {
  tbody.ttl-order-table {
    tr {
      td {
        a {
          span.icon-eye {
            position: relative;
            top: 2px;
          }
        }

        span.icon-password {
          position: relative;
          top: 1px;
        }
      }
    }
  }
}
.custom-date-format {
  position: relative;
  input[type="date"]::-webkit-datetime-edit-text {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-month-field {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-day-field {
    -webkit-appearance: none;
    display: none;
  }
  input[type="date"]::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    display: none;
  }
  .form-control {
    color: transparent !important;
    &::placeholder {
      display: none !important;
      color: transparent !important;
    }
   
  }
  span {
    position: absolute;
    top: 8px;
    left: 15px;
  }
}
.fs-lg-13 {
  @media (max-width: 1399px) {
    font-size: 13px !important;
  }
}
.search-wrap {
  .icon-close1 {
    right: 10px;
    @media (max-width: 1399px) {
      right: 5px;
    }
  }
}
.form-control{
   &:focus{
      border-color: #5dca9d;
      box-shadow: 0 0 0 .2rem rgba(83, 255, 184, 0.25);
    }
}
.wd-auto {
  width: auto !important;
}
.text-success {
  color: #55a026;
}
.img-div-box {
  background: #eee;
  border-radius: 10px;
  border: solid 1px #ccc;
}

.topbarWrapper {
  .logo {
    background: var(--primary-color-new);
  }
}
.sidebar {
  background: var(--primary-color-new);

  .sidebarNav {
    padding-top: 0;

    .dropdown-container {
      background: var(--primary-color-new);
      padding-left: 0;

      & > a {
        padding-left: 40px;
      }
    }

    a {
      font-family: "Kanit", sans-serif !important;
      color: var(--white-color);
      margin-bottom: 2px;
      &.noActives {
        color: var(--white-color);
        background: var(--primary-color-new);
        &:hover {
          color: var(--primary-color-new);
          background: var(--secondary-color);
          span[class^="icon-"] {
            &::before {
              fill: var(--secondary-color) !important; 
            }
          }
        }
        &.active {
          color: var(--primary-color-new);
          background: var(--secondary-color);
          &:hover {
            color: var(--primary-color-new);
            background: var(--secondary-color);
            span[class^="icon-"] {
              &::before {
                fill: var(--secondary-color) !important; 
              }
            }
          }
        }
      }
      span[class^="icon-"],
      span {
        fill: var(--white-color);
        color: var(--white-color);
        font-family: "Kanit", sans-serif;

        &::before {
          color: var(--white-color);
        }
      }

      .icon-order-lists {
        svg {
          path {
            fill: var(--white-color);
          }
        }
      }

      &:hover,
      &.active-toggle {
        background: var(--secondary-color);
        color: var(--primary-color-new);

        span[class^="icon-"],
        span {
          fill: var(--primary-color-new);
          color: var(--primary-color-new);
          font-family: "Kanit", sans-serif;

          &::before {
            color: var(--primary-color-new);
          }
        }

        .icon-order-lists {
          svg {
            path {
              fill: var(--primary-color-new);
            }
          }
        }

        .icon-order-lists {
          span {
            &.order-title {
              color: var(--primary-color-new);
            }
          }
        }

        &::after {
          background: var(--primary-color-new);
        }
      }
    }
  }
}

.CustomerTable {
  .table {
    th {
      font-size: 16px;
      color: var(--tertiary-color);
      font-family: "Kanit", sans-serif;
      font-weight: 400;
    }
  }
}

.profile-notifications {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
  align-items: center;

  .icon-settings {
    margin-top: 0;
    margin-right: 0;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  appearance: none; /* For most modern browsers */
  -webkit-appearance: none; /* For Safari and older WebKit browsers */
  -moz-appearance: none; /* For Firefox */
  opacity: 0;
}

input[type="date"] {
  &.form-control {
    background-image: url("./assets/images/calender-new.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 17px;
    background-position: right 11px top 9px;
    cursor: pointer;
  }
}

.pr-wrap {
  & > h4 {
    color: var(--tertiary-color);
    font-family: "Kanit", sans-serif !important;
    font-size: 30px;
    font-weight: 400;
  }

  & > p.color-grey {
    font-family: "Kanit", sans-serif !important;
    font-size: 18px;
  }
}

/* Custom switch */
.custom-switch {
  .custom-control-label{
    color: var( --dark-gray-color);
    &::before {
      left: -3.5rem;
      width: 3rem;
      height: 28px;
      pointer-events: all;
      border-radius: 32px;
      top: -3px;
    }
    &::after {
        top: 0px;
        left: -52px;
        width: calc(1.75rem - 6px);
        height: calc(1.75rem - 6px);
        border-radius: 32px;
    }
  }
}

.custom-control-input:checked~.custom-control-label::before{
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
}

.custom-control-input:checked~.custom-control-label::after{
  left: -46px;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before{
  background-color: rgba(#ffcd05, 0.25);
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 .2rem rgba(#ffcd05, .25);
  border-color: rgba(#ffcd05, .6) !important; 
}
.deliveryUnitdropdown{
  .MuiInputBase-root {
    margin-left: 1rem;
    background: none;
    border: 1px solid #BBBBBB;
    border-radius: 10px!important;

  }
  .MuiFilledInput-underline::before
  {
    border-bottom: none;
  }
  
  .MuiSelect-root {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff !important;
    border-radius: 10px !important;
    border-bottom: none  !important;
    padding-top: 10px  !important;
    padding-bottom: 10px !important;
    padding-right: 35px !important;
    font-size: 22px;
    font: Inter;
    line-height: 100%;

  }
  .MuiSelect-icon{
 color: #000!important;
  }
  .MuiFilledInput-underline:after{
    border-bottom: none!important;
  }
  .MuiInputBase-input{
   color :#707070!important
  }
  .MuiButtonBase-root {
    color: #707070;
    ;
  }
}


.tooltip-inner{
  width: 400px!important;
}

.FontSIze {
  font-size: 22px!important; 
  color: #444!important;
  font: Inter;
  line-height: 100%;
}

.Toastify__toast{
  .Toastify__toast-body{
    padding-right: 1rem;
  } 
}