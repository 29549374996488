* {
    word-break: break-word;
  }
  .d-block {
    display: block;
  }

  .terms-section {
    padding-bottom: 50px;
  }

  .terms-section h1.heading {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 32px;
    font-weight: 600;
    color: black;
  }

  .terms-section h3.heading {
    font-size: 40px;
    font-weight: 600;
    color: black;
  }

  .heading-two {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px !important;
  }

  .text-black {
    color: black;
  }

  .terms-section p {
    font-size: 16px;
  }

  .terms-section ul li {
    color: black;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .text-blue {
    color: var(--blue-color);
  }

  h2.heading2 {
    font-size: 28px;
  }

  h2.heading3 { 
    font-size: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 600;
  }

  h2.headingh2 {
    font-weight: 600;
  }

  ul.list-number li {
    list-style: none;
  }

  h2.heading4 {
    font-weight: 500;
    font-size: 20px;
  }

  ul.list-none li {
    list-style: none;
    display: flex;
  }

  ul.list-none li>span {
    min-width: 15px;
  }

  span.w20 {
    min-width: 20px;
  }

  p {
    color: black;
  }

  .text-red {
    color: red;
  }

  h3.heading4 {
    font-size: 20px;
    font-weight: 500;
  }

  .list-dash li {
    list-style: none;
    display: flex;
  }

  .list-dash li>span {
    margin-right: 10px;
  }

  h2.headingh2 {
    font-size: 28px;
  }

  .heading3>span {
    font-size: 16px;
    font-weight: 400;
  }

  @media (min-width: 768px) {
    ul.circle-list {
      padding-left: 3rem;
    }
  }