
.page-body-wrapper {
    min-height: calc(100vh - 70px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
    padding-top: 70px;
}
.page__wrapper {    
    background: #f4f4f4; 
    .content-wrapper {
        background: #f4f4f4; 
    }
}
.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
    padding-top: 0;
}
.content-wrapper {
    background: #FFF;
    padding: 2.25rem 2.25rem 30px 2.25rem;
    /* width: 100%; */
    -webkit-flex-grow: 1;
    flex-grow: 1;
    border-top: 2px solid #eaeaf2;
    /* border-radius: 10px; */
    margin: 0;
    min-height: 80vh;
}
.boxShadow1 {
    // box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
}
.deliva-form{
    border-radius: 15px;
    background: #fff; 

}
.login-input{
    border: none;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    height: 59px;
    padding: .375rem 1.2rem;
}
.login-wrap{
    width: 85%;  
}
.form-group {
    position: relative; 
    margin-bottom: 1.5rem;
}
.deliva-btn{
    background: var(--primary-color-new); 
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 0;
    padding: .275rem 2rem;
    font-size: 20px;
    height: 50px; 
    outline: 0; 
    &:hover, &:focus {
        background: var(--primary-color-new) !important;
        box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.05) !important;
    }
}
.resetpass{
    border-radius: 10px;
    height: 45px; 
    padding: .275rem 2.5rem;
    margin-top: 0.5rem !important;
}
.pointer{
    cursor: pointer;
}
.login-img{
    margin-left: -38px;
}
.text-grey{
    color: #555555 !important;
}
.mtb-4{
    margin: 2rem 0!important;
}
.text-blue {
    color: var(--blue-color);
}
@media (max-width: 767px) { 
    .login { 
        justify-content: center;
    }
    .content-wrapper { 
        &.login {
            padding: 0 0 0 0;
        }
    }
    .login-img {
        margin-left: 0;
    }
    .justCenter {
        justify-content: center; 
    }
    .pr-0 {
        padding-right: 15px !important;
    }
}
@media (min-width: 768px) {
    .login-marg {
        margin-left: -15px; 
    }
}
